import 'slick-carousel';

export default {
  getOptions(type, navFor = false) {
    if (type === 'hero') {
      return {
        arrows: true,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        dots: true,
        dotsClass: 'paging',
        customPaging: (slider, i) => {
          return `${((i + 1 > 9) ? i+1 : 0+''+(i+1))} / ${((slider.slideCount > 9) ? slider.slideCount : 0+''+(slider.slideCount))}`;
        }
      };
    } else if (type === 'stories') {
      return {
        arrows: true,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        dots: false,
        adaptiveHeight: true
      };
    } else if (type === 'prodInnerTop') {
      return {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: navFor,
        dragable: false,
        adaptiveHeight: true,
      }
    } else if (type === 'prodInnerBot') {
      return {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        asNavFor: navFor,
        centerMode: true,
        centerPadding: '0'
      }
    } else if (type === 'related') {
      return {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
      }
    }

  },
  init(slider, type, navFor) {
    const options = this.getOptions(type, navFor);
    slider.slick(options);
  }
}
