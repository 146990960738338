export default {
  init(items) {
    items.each((i, item) => {
      $(item).find('.toggler').on({
        click() {
          $(item).toggleClass('open');
          $(item).siblings('.open').removeClass('open');
        }
      })
    });
  },
  resize(items) {
    items.each((i, item) => {
      const wrap = $(item).find('.contentWrap');
      const inner = wrap.find('.content');

      wrap.css('height', parseFloat(inner.css('height')));
    });
  }
}
