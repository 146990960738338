import modalScript from './modal';
import form from './form';

export default {
  init(btn, modal) {
    btn.on('click', () => {
      modalScript.openModal(modal);
    });

    modal.find('.hideable-opener input').on('change', (e) => {
      this.toggleHideable($(e.target));
    });

    form.init(modal.find('form'));
  },
  toggleHideable(checkbox) {
    const value = checkbox.prop('checked');
    const id = checkbox.closest('.checkbox').data('id');

    if (value) {
      $(`#${id}`).css('display', 'flex').hide().slideDown(300);
    } else {
      $(`#${id}`).slideUp(300);
    }
  }
};
