export default {
  init(formWrap) {
    formWrap.find('.input').each((i, input) => {
      $(input).find('input').on('keydown keyup change', (e) => {
        const val = $.trim($(e.target).val());

        if (val.length > 0) {
          $(input).addClass('full');
        } else {
          $(input).removeClass('full');
        }
      });

      $(input).find('input').on('focusin', (e) => {
        $(input).addClass('focus');
      });

      $(input).find('input').on('focusout', (e) => {
        $(input).removeClass('focus');
      });
    });

    formWrap.find('.select').each((i, select) => {
      $(select).find('select').on('change', (e) => {
        const val = $.trim($(e.target).val());

        if (val.length > 0) {
          $(select).addClass('full');
        } else {
          $(select).removeClass('full');
        }
      });
    });
  },
  showSuccess(formWrap, timing = 300) {
    formWrap.find('.full').removeClass('full');
    formWrap.find('.form_inner').fadeOut(timing, () => {
      formWrap.find('.form_success').css('display', 'flex').hide().fadeIn(timing);
    });
  },
  hideSuccess(formWrap, timing = 300) {
    formWrap.find('.form_success').fadeOut(timing, () => {
      formWrap.find('.form_inner').fadeIn(timing);
    });
  }
}
