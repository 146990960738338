import $ from 'jquery';
window.jQuery = $;
window.$ = $;
require('sticky-kit/dist/sticky-kit.js');

export default {
  init(item) {
    item.stick_in_parent({
      offset_top: 90
    });
  },
  recalc(item) {
    item.trigger("sticky_kit:recalc");
  }
}
