export default {
  applyBtnInit(btn) {
    const $self = this;
    btn.on('click', function(){
      const id = $(this).data('jobid');

      IN.User.authorize(() => {
        $self.getApplicantInfo(id);
      });
    });
  },
  getApplicantInfo(id) {
    if (typeof IN.API !== 'undefined') {
      IN.API.Profile("me").fields(["first-name", "last-name", "email-address", "public-profile-url"]).result((data) => {
        this.postApplication(id, {
          firstName: data.values[0].firstName,
          lastName: data.values[0].lastName,
          email: data.values[0].emailAddress,
          linkedin: data.values[0].publicProfileUrl
        });

        console.log(`linkedin resp: `, data);
      }).error(function (err) {
        console.log(err);
        alert('There was an error. Page will reload itself');
        window.location.reload();
      });
    } else {
      alert('There was an error. Page will reload itself');
      window.location.reload();
    }
  },
  postApplication(id, applicant) {
    $.post(for_ajax.url, {
      action: 'post_application',
      job: id,
      applicant: applicant
    }).done((resp) => {
      $('.linkedInBtn[data-jobid="'+id+'"]').addClass('applied').html('Applied');
    }).fail((err) => {
      console.log(err);
      alert('There was an error. Page will reload itself');
      window.location.reload();
    });
  }
}
