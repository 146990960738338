const $ = require('jquery');
const jQueryBridget = require('jquery-bridget');
const Isotope = require('isotope-layout');
require('isotope-packery');

jQueryBridget( 'isotope', Isotope, $ );

export default {
  init(layout){
    const layoutClass = '.'+layout.attr('class');
    const layoutItemClass = layoutClass+'-item';
    const layoutSizerClass = layoutClass+'-sizer';

    $(layoutClass).isotope({
      percentPosition: true,
      itemSelector: layoutItemClass,
      layoutMode: 'packery',
      transitionDuration: 1000,
      filter: '.active',
      packery: {
        gutter: 0,
        columnWidth: layoutSizerClass
      }
    });
  },
  buildArrays(filters, layoutItemClass) {
    return Object.keys(filters).map((key, idx) => {
      const ids = [];
      const filter = (filters[key] === 'all' || filters[key] === '') ? '' : `[data-${key}=${filters[key]}]`;
      const filtered = $(`${layoutItemClass}${filter}`);

      filtered.each((idx, item) => {
        ids.push($(item).attr('id'));
      });

      return ids;
    });
  },
  isoFilter(layoutClass, layoutItemClass, matching){
    $(layoutItemClass).removeClass('active');

    matching.forEach((id, idx) => {
      $(`${layoutItemClass}#${id}`).addClass('active');
    });

    $(layoutClass).isotope({ filter: '.active' });
  },
  filter(layout, filters) {
    const layoutClass = '.'+layout.attr('class');
    const arrays = this.buildArrays(filters, layoutClass+'-item');

    const matching = (arrays.length > 1) ? this.returnMatching(arrays) : arrays[0];

    this.isoFilter(layoutClass, layoutClass+'-item', matching);
  },
  returnMatching(arrays) {
    return arrays.shift().reduce(function(res, v) {
      if (res.indexOf(v) === -1 && arrays.every(function(a) {
        return a.indexOf(v) !== -1;
      })) res.push(v);
      return res;
    }, []);
  }
}
