import 'jquery-powertip';

var globalTimeout;

function powerTipHide() {
  $.powerTip.hide();
}

export default {
  init(triggers) {
    triggers.powerTip({
      smartPlacement: true,
      mouseOnToPopup: true
    });

    triggers.on({
      powerTipOpen() {
        $(this).addClass('in');
      },
      powerTipClose() {
        $(this).removeClass('in');
      }
    })
  }
};
