import modal from './modal';

export default {
  init(className) {
    $('body').on('click', className, (e) => {
      const employee = this.getEmployee(e.currentTarget.dataset.id);
    });
  },
  getEmployee(id) {
    $.get(`${for_ajax.url}`, {
      action: 'get_employee',
      id: id
    }).done((resp) => {
      this.buildModal(JSON.parse(resp));
    }).fail((err) => {
      alert(err.message);
    });
  },
  buildModal(data) {
    $('.modal.employee .content').html(data.template);
    modal.openModal($('.modal.employee'));
    return data;
  }
}
