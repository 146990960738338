import PerfectScrollbar from 'perfect-scrollbar';

export default {
  init(table) {
    new PerfectScrollbar(table.get(0), {
      wheelPropagation: true,
      suppressScrollY: true,
      useBothWheelAxes: true,
    });
  }
};
