function whichTransitionEvent() {
  var t,
    el = document.createElement('fakeelement'),
    transitions = {
      'transition': 'transitionend',
      'OTransition': 'oTransitionEnd',
      'MozTransition': 'transitionend',
      'WebkitTransition': 'webkitTransitionEnd'
    };

  for (t in transitions) {
    if (el.style[t] !== undefined) {
      return transitions[t];
    }
  }
}

function whichAnimationEvent() {
  var t,
    el = document.createElement("fakeelement"),
    animations = {
      "animation": "animationend",
      "OAnimation": "oAnimationEnd",
      "MozAnimation": "animationend",
      "WebkitAnimation": "webkitAnimationEnd"
    };

  for (t in animations) {
    if (el.style[t] !== undefined) {
      return animations[t];
    }
  }
}

const tranEv = whichTransitionEvent();
const animEv = whichAnimationEvent();
const isMS = navigator.userAgent.indexOf('MSIE') != -1 || !!navigator.userAgent.match(/Trident.*rv\:11\./) || navigator.userAgent.indexOf('Edge') != -1;
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|CriOS/i.test(navigator.userAgent);
const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;

export default {
  tranEv,
  animEv,
  isMS,
  isMobile,
  iOS,
  isSafari,
  isAndroid
};
