const Isotope = require('isotope-layout');

export default {
    layout: document.querySelector('.filter_container'),
    regionSelect: document.querySelector('select[name="region"]'),
    countrySelect: document.querySelector('select[name="country"]'),
    items: null,
    data: null,
    selectedRegion: 'all',
    selectedCountry: 'all',
    countriesByRegions: null,
    isotopeInstance: null,

  init() {
    this.setData();
    this.buildList();
    this.buildSelects();
    this.setIsotope();
    this.setEvents();
  },

  setEvents() {
    const that = this;
    this.regionSelect.addEventListener('change', function() {
        that.selectedRegion = this.value;
        that.setOptions(that.countrySelect, that.countriesByRegions[this.value]);
        that.selectedCountry = 'all';
        that.filter();
    });
    this.countrySelect.addEventListener('change', function() {
        that.selectedCountry = this.value;
        that.filter();
    });
  },

  filter() {
    if (!this.items || !this.isotopeInstance) return;

    for (let i = 0, j = this.items.length; i < j; i++) {
        const item = this.items[i];
        const itemRegion = item.dataset.region;
        const itemCountry = item.dataset.country;

        if (
            (itemRegion === this.selectedRegion ||
            this.selectedRegion === 'all') &&
            (itemCountry === this.selectedCountry ||
            this.selectedCountry === 'all')
        ) {
            item.classList.add('active');
        } else {
            item.classList.remove('active');
        }
    }

    this.isotopeInstance.arrange({ filter: '.active' });
  },

  setIsotope() {
      Promise.all(
        Array.from(document.images).
        filter(img => !img.complete).
        map(img => new Promise(resolve => { 
          img.onload = img.onerror = resolve; 
        }))).then(() => {
          this.isotopeInstance = new Isotope(this.layout, {
            percentPosition: true,
            itemSelector: '.distributor',
            layoutMode: 'masonry',
            transitionDuration: 1000,
            filter: '.active',
            masonry: {
                gutter: 0,
                horizontalOrder: true
            }
          });
          window.dispatchEvent(new Event('resize'));
          this.layout.classList.remove('opacity');
    });
  },

  buildSelects() {
    if (!Array.isArray(this.data)) return;
    const regions = new Set();
    const countries = new Set();
    const countriesByRegions = {};
    this.data.forEach(({ region, country }) => {
        regions.add(region);
        countries.add(country);
        if (!countriesByRegions[region]) {
            countriesByRegions[region] = new Set();
        }
        countriesByRegions[region].add(country);
    });
    regions.forEach(region => this.createOption(region, this.regionSelect));
    countries.forEach(country => this.createOption(country, this.countrySelect));
    this.countriesByRegions = countriesByRegions;
    this.countriesByRegions['all'] = new Array(...countries);
  },

  setData() {
      try {
        const json = JSON.parse(document.getElementById('distributorsData').innerHTML);
        this.data = json;
        this.selectedRegion = this.data[0].region;
        this.selectedCountry = this.data[0].country;
      } catch({ message }) { console.log(`Error! ${message}`) }
  },

  buildList() {
    if (!Array.isArray(this.data)) return;
    this.data.forEach(element => this.createListElement(element));
    this.items = document.querySelectorAll('.distributor');
  },

  createListElement({ region = '', country = '', logo = '', name = '', link = '', email = '', link_name = '' }) {
    const inside = `
      <img src="${logo}" alt="" />
      <div class="info_holder">
        <div class="name">${name}</div>
        <a href="${link}" class="link" target="_blank">${link_name}</a>
        <a href="mailto:${email}" class="link">${email}</a>
      </div>
    `;

    const template = document.createElement('div');
    template.classList.add('distributor');
    template.classList.add('active');
    template.setAttribute('data-region', region);
    template.setAttribute('data-country', country);
    template.innerHTML = inside;
    this.layout.appendChild(template);
  },

  setOptions(select, newOptions) {
    const options = select.querySelectorAll('option');
    const firstOption = options[0];
    const secondOption = options[1];
    select.innerHTML = "";
    select.appendChild(firstOption);
    select.appendChild(secondOption);
    
    newOptions.forEach(option => this.createOption(option, select));
  },

  createOption(option, select) {
    const template = document.createElement('option');
    template.innerHTML = option;
    template.value = option;
    select.appendChild(template);
  }
}
