import form from './form';


export default {
  init(btn) {
    $("body").on("click", ".modal .close", (e) => {
      this.closeModal($(e.target).closest(".modal"));
    });

    $("body").on("click", ".modal", (e) => {
      if (!($(e.target).is(".contentWrap") || $(e.target).closest(".contentWrap").length > 0)) {
        this.closeModal($(e.target).closest(".modal"));
      }
    });
  },
  openModal(modal) {
    $('body').addClass('fixed');
    modal.fadeIn(300);
  },
  closeModal(modal) {
    modal.fadeOut(300, function(){
      $(this).find('.content.cleanable').html('');
      if ($(this).find('form').length > 0) {
        form.hideSuccess(modal.find('form'), 0);
      }
      $('body').removeClass('fixed');
    });
  }
}
