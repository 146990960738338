import * as constants from './scripts/common';
import slickSlides from './scripts/slickSlides';
import timelineScroll from './scripts/timelineScroll';
import overflow from './scripts/overflow';
import distrTip from './scripts/distrTip';
import teamTope from './scripts/teamTope';
import prodsTope from './scripts/prodsTope';
import sticky from './scripts/sticky';
import accordion from './scripts/accordion';
import modal from './scripts/modal';
import employee from './scripts/employee';
import modalForms from './scripts/modalForms';
import form from './scripts/form';
import linkedin from './scripts/linkedin';
import distributors from './scripts/distributors';

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

const onLoad = (e) => {
  inits();
  onResize(e);
};

const onResize = (e) => {
  onScroll(e);

  if ($('.jobs_list-item').length > 0) {
    accordion.resize($('.jobs_list-item'));
  }
};

const onScroll = (e) => {
  if ($('.loader:not(.fading)').length > 0) {
    $('.loader').addClass('fading').fadeOut(300, function () {
      $(this).remove();
    });
  }
  if ($('.contacts').length > 0) {
    if (window.pageYOffset > 100) {
      $('.header').addClass('scrolled');
    } else {
      $('.header').removeClass('scrolled');
    }
  }
};

const inits = () => {
  if ($('.linkedInBtn').length > 0) {
    $('.linkedInBtn').each((i, btn) => {
      linkedin.applyBtnInit($(btn));
    });
  }

  if ($('.modal').length > 0) {
    modal.init();
  }

  if ($('section.distributors').length) {
    distributors.init();
  }

  if ($('.hero_slides').length > 0) {
    slickSlides.init($('.hero_slides'), 'hero');
  }

  if ($('.stories_slides').length > 0) {
    slickSlides.init($('.stories_slides'), 'stories');
  }

  if ($('.timeline_list').length > 0) {
    timelineScroll.init($('.timeline_list'), $('.timeline_list-item'));
    overflow.init($('.timeline_list'));
  }

  if ($('.table').length > 0) {
    $('.table').each((i, table) => {
      overflow.init($(table));
    });
  }

  if($('.svg_distr > g').length > 0) {
    distrTip.init($('.svg_distr > g'));
  }

  if ($('.contacts').length > 0) {
    $('.header').addClass('cntcts');
  }

  if ($('.tabs').length > 0) {
    sticky.init($('.tabs'));
  }

  if ($('.jobs_list-item').length > 0) {
    accordion.init($('.jobs_list-item'));
  }

  if ($('.team_list-item').length > 0) {
    teamListInit();
  }

  if ($('.products_list-item').length > 0) {
    prodsListInit();
  }

  if ($('.searchToggle').length > 0) {
    searchToggleInit();
  }

  if ($('.form').length > 0) {
    form.init($('.form'));
  }

  if ($('.product_slides').length > 0) {
    slickSlides.init($('.product_slides-images'), 'prodInnerTop', '.product_slides-thumbs');
    slickSlides.init($('.product_slides-thumbs'), 'prodInnerBot', '.product_slides-images');
  }

  if ($('.related_slides').length > 0) {
    slickSlides.init($('.related_slides'), 'related');
  }

  if ($('.infos.prod').length > 0) {
    productInnerInit();
  }

  if ($('.btn.employee').length > 0) {
    employee.init('.btn.employee');
  }

  if ($('.btn.requestForm').length > 0 && $('.modal.requestForm').length > 0) {
    modalForms.init($('.btn.requestForm'), $('.modal.requestForm'));
  }

  if ($('.btn.jobAlerts').length > 0 && $('.modal.jobAlerts').length > 0) {
    modalForms.init($('.btn.jobAlerts'), $('.modal.jobAlerts'));
  }

  if ($('.btn.sideForm').length > 0 && $('.sideForm form').length > 0) {
    form.init($('.sideForm form'));
    $('.btn.sideForm').on('click', function(){
      const type = $(this).data('type');

      $(this).closest('.articleStats').fadeOut(300, function(){
        $(this).siblings('.sideForm').fadeIn(300, function(){
          $(this).find('.hidden input').val(type);
        });
      });
    });
  }

  $('[href^="#"]').on('click', function(e) {
    const href = $(this).attr('href');

    if ($(href).length > 0) {
      e.preventDefault();
      scrollTo($(href).offset().top, 400);
    }
  });
}

const scrollTo = (where, timing) => {
  const top = where - parseFloat($('.header').css('height'));
  $((constants.iOS) ? "body" : "body, html").stop().animate({ scrollTop: top }, timing, function() {
    $(window).scrollTop(top);
  });
}

const productInnerInit = () => {
  if ($('.infos_tabs').length > 0) {
    if ($('.infos_tabs input[checked]').length > 0) {
      $('.infos_content-block').removeClass('active');
      $('.infos_content-block#'+$('.infos_tabs input[checked]').val()).addClass('active');
    }

    $('.infos_tabs').on('change', function(){
      const values = {};

      $(this).serializeArray().map((field) => {
        values[field.name] = field.value;
      });

      $('.infos_content-block#'+values.tab).addClass('active').siblings('.active').removeClass('active');
    });
  }
}

const prodsListInit = () => {
  if ($('.products_catChange').length > 0) {
    $('.products_catChange').on('change', function(){
      window.location.href = $(this).find('select').val();
    });
  }

  if ($('.products_tabs').length > 0) {
    if ($('.products_tabs input[checked]').length > 0) {
      $('.products_list-item').removeClass('active');
      $('.products_list-item[data-tab="'+$('.products_tabs input[checked]').val()+'"]').addClass('active');
    }

    $('.products_tabs').on('change', function(){
      const values = {};

      $(this).serializeArray().map((field) => {
        values[field.name] = field.value;
      });

      prodsTope.filter($('.products_list'), values);
    });
  }

  prodsTope.init($('.products_list'));
};

const searchToggleInit = () => {
  $('body').on('click', '.searchToggle', function() {
    if ($('.search').length > 0) {
      $('.search input[type="text"]').focus();
    } else {
      $(this).addClass('open').find('input[type="text"]').focus();
    }
  });

  $('body').on('click', function(e){
    if ($('.search').length < 1) {
      const target = $(e.target).is('.searchToggle') || $(e.target).closest('.searchToggle').length > 0;

      if (!target) {
        $('.searchToggle').removeClass('open');
      }
    }
  });
}

const teamListInit = () => {
  teamTope.init($('.team_list'));
  if ($('#tab-sales').prop('checked')) {
    $('.team_filters').css('display', 'flex');
  }

  $('.team_tabs').on('change', function(){
    const values = {};

    $(this).serializeArray().map((field) => {
      values[field.name] = field.value;
    });

    if (values.tab === 'sales') {
      $('.team_filters').css('display', 'flex').hide().slideDown(300);
    } else {
      $('.team_filters').slideUp(300, function(){
        $(this).get(0).reset();
      });
    }


    sticky.recalc($('.team_tabs'));

    teamTope.filter($('.team_list'), values);
  });

  $('.team_filters').on('change', function(){
    const values = {};

    $('.team_tabs').serializeArray().map((field) => {
      values[field.name] = field.value;
    });

    $(this).serializeArray().map((field) => {
      if (field.value !== '') {
        values[field.name] = field.value;
      }
    });

    teamTope.filter($('.team_list'), values);
  });
}

$('.close').on('click', function() {
  $(this).closest('.open').removeClass('open');
  $('html').removeClass('open');
});

$(window).on('scroll', onScroll);
$(window).on('resize', onResize);
$(window).on('load', onLoad);
