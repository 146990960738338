import PerfectScrollbar from 'perfect-scrollbar';

export default {
  init(list, items) {
    let highest = 0;

    items.each((idx, item) => {
      console.log(parseFloat($(item).css('height')));
      if (parseFloat($(item).css('height')) > highest) {
        highest = parseFloat($(item).css('height'));
      }
    });

    console.log(highest);

    items.css({
      height: highest
    });

    list.css({
      height: 2 * (highest) + 34,
    }).addClass('show');
  }
};
